.body_container {
    display: flex;
    margin-top: 40px;
    border-top: 1px solid var(--color1);
}
.scroll_container {
    display:flex;
    flex-direction: column;
    margin: 0  10px ;
    align-items: center;
    border-right: 1px solid var(--color1);
}

.exercise_container{ 
    margin:0 auto;
}

.exercise_title {
    font-size: 40px;
    text-align: center;
    margin: 10px auto;
    color: var(--color2);
}

.exercise_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* exerciseCard */

.exercise_card_container {
margin: 10px 10px;
background-color: white;
border-radius: 10px;
align-items: center;
text-align: center;
transition-duration: 1s;
}

.exercise_card_container:hover {
    transform: scale(1.05);
}

.exercise_card_image {
    border-radius: 10px;
}

.exercise_card_button_container {
    margin: 10px 20px;
}

.card_button {
    margin-right: 20px;
    font-size: 24px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 15px;
    border: none;
}

.exercise_card_button1 {
   background-color: var(--color1);
   color: var(--text);
}

.exercise_card_button2 {
    background-color: var(--color2);
    color: var(--text);
}


.exercise_card_title {
    font-size: 18px;
    margin:10px 5px;
    color: var(--back);
    font-size: 24px;
}

.more_container{
    display: flex;
    justify-content: center;
}

.more_show {
    font-size: 30px;
    text-transform: uppercase;
    color: var(--color1);
    cursor: pointer;
    margin-right:20px ;
}

.more_show:hover{
    color: var(--color2);
    transition-duration: 1s;
}


@media only screen and (max-width: 768px) {
    .exercise_container {
        border:none
    }

    .scroll_container {
        display: none;
    }

    .exercise_card_container {
        width: 40%;
        height: 30%;
        margin: 5px 5px;
    }

    .exercise_card_image {
        width: 40%;
    }

    .exercise_card_container {
        margin: 5px 10px;
    }

    .card_button {
        margin-right: 10px;
        font-size: 16px;
        padding: 3px 5px;
        border-radius: 10px;
    }

    .exercise_card_title {
        margin:10px 5px;
        font-size: 18px;
    }

    .more_show {
        font-size: 20px;
        margin-right:20px ;
    }
}