.detail_container {
    display: flex;
}

.detail_main{
    display: flex;
    width: 100%;
    margin-top: 100px;
    margin-inline: 30px;
    box-shadow: 0px 0px 57px 0px var(--color1);
    border-radius: 20px;
}


.detail_image{
    width: 50%;
    border-radius: 20px;
}

.detail_text_container {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    background-color: var(--text);
    width: 50%;
    color: var(--back);
    padding: 30px;
}

.detail_name {
    margin: 10px 0;
    text-transform: uppercase;
}

.detail_explain{
    margin: 20px 0;
}

.icons_container{
    justify-content: flex-start;
    margin-top: 40px;
}

.icon {
    margin: 20px 0;
    text-transform: uppercase;
    display: flex;
}

.icon_title {
    margin-left: 20px;
}

@media only screen and (max-width: 768px) {
    .detail_main {
        display: flex;
        flex-direction:column
    }
     .detail_image {
        width: 100%;
     }

     .detail_text_container {
        width: 100%;
        font-size: 20px;
        padding: 5px;
     }


     .detail_explain {
        margin: 5px 0;
     }
     .icons_container {
        margin-top: 5px;
     }
     .icon {
        size: 10;
     }
}