:root {
  --text: #f5f7f7;
  --back: #000407;
  --color1: #79031D;
  --color2: #EDB518;
}

body {
  background-color:  var(--back);
  color: var(--text);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
