.search_container {
    margin-top: 30px;
    text-align: center;
} 

.search_title {
    font-size: 50px;
    margin-bottom: 10px;
} 

.search_main {
    background-color: white;
    border-radius: 10px;
    border: 2px solid var(--color2);
    margin: auto;
    width: 70%;
}
.search_input {
    border: none;
    width: 80%;
    outline: none;
    padding: 0 5px;
    font-size: 24px; 
 }
.search_button{
    width: 20%;
    padding: 5px 10px;
    font-size: 24px;
    background-color: var(--color2);
    border:none;
    color: var(--text);
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.search_button:hover {
    cursor: pointer;
    background-color: var(--color1);
    color: var(--text);
    transition: all .5s;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .search_button {
        font-size: 18px;
        width: 30%;
    }

    .search_input {
        font-size: 18px;
        width: 70%;
    }

    .search_title {
        font-size: 36px;
    }
}