.home_container{
    width: 100vw;
    height: 100vh;
    opacity: 0.6;
    display: flex;
}

.home_main {
    z-index: -1;
    background-image: url("../../assets/background.jpg");
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.text_container {
    position: absolute;
    top: 347px;
    left: 100px;
    font-size: 80px;
}

.word_select {
    color:crimson;
}

@media only screen and (max-width: 768px) {
    .text_container {
        font-size: 30px;
        top: 38%;
        left: 20%
    }

}

